import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class AppComponent implements OnInit {
  message = '';

  constructor(private http: HttpClient, private titleService: Title) {
    this.http.get('/api/message')
      .subscribe((resp: any) => this.message = resp.text);
  }

  ngOnInit(): void {
    // this.breadcrumbService.breadcrumbChanged.subscribe(crumbs => {
    //   this.titleService.setTitle(this.createTitle(crumbs));
    // });
  }
  onActivate(_event:any){
    window.scroll(0,0);
  }
  private createTitle([]) {
    const title = "Campa - Mobile App & Shop - Angular Template";
    const titles = ["NA"];

    if (!titles.length) { return title; }

    const routeTitle = this.titlesToString(titles);
    return `${title}${routeTitle}`;
  }

  private titlesToString(titles: any[]) {
    return titles.reduce((prev, curr) => {
      return `${prev} | ${curr.displayName}`;
    }, '');
  }
}
